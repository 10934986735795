import AcctRoutes from 'containers/modules/Account/Routes'
import DevExpressRoutes from 'containers/modules/DexExpress/Routes'
import ReportRoutes from '../modules/Report/Routes'
import AccessSecurityRoutes from '../modules/AccessSecurity/Routes'
import { Navigate, useRoutes } from 'react-router-dom'
import MainLayout from '../app/MainLayout'
import AuthRoutes from '../modules/Authentication/Routes'
import { AuthGuard } from './AuthGuard'
import { Blank } from './Blank'
import { AUTH_PATH } from '../modules/Authentication/Routes'

export function MainRoutes() {
  const RootRoutes = useRoutes([
    {
      path: '',
      element: <AuthGuard />,
      children: [
        {
          path: '',
          element: <MainLayout />,
          children: [
            {
              path: '',
              element: <Navigate to="/report" />,
            },
            {
              path: 'profile',
              children: [...AcctRoutes],
            },
            {
              path: 'dx',
              children: [...DevExpressRoutes],
            },
            {
              path: 'report',
              children: [...ReportRoutes],
            },
            // {
            //   path: 'access-security',
            //   children: [...AccessSecurityRoutes],
            // },
          ],
        },
      ],
    },
    {
      path: '',
      element: <Blank />,
      children: [...AuthRoutes],
    },
    {
      path: '*',
      element: <Navigate replace to={AUTH_PATH.NOT_FOUND}></Navigate>,
    },
  ])

  return RootRoutes
}
// export default RootRoutes
