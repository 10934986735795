import React, { lazy } from 'react'
import { RouteObject } from 'react-router-dom'
import AutoLogin from './AutoLogin'

const Login = lazy(() => import('./Login'))
const Logout = lazy(() => import('./Logout'))
const ForgotPassword = lazy(() => import('./ForgotPassword'))
const ResetPassword = lazy(() => import('./ResetPassword'))
const NotFound = lazy(() => import('./NotFound'))

export const AUTH_PATH = {
  LOGIN: '/login',
  LOGOUT: '/logout',
  FORGOT_PASSWORD: '/forgot-password',
  RESET_PASSWORD: '/new-pwd/:token',
  NOT_FOUND: '/404-not-found',
}

const AuthRoutes: RouteObject[] = [
  {
    path: 'login',
    element: <Login />,
  },
  {
    path: 'autologin',
    element: <AutoLogin />,
  },
  {
    path: 'logout',
    element: <Logout />,
  },
  {
    path: 'forgot-password',
    element: <ForgotPassword />,
  },
  {
    path: 'new-pwd/:token',
    element: <ResetPassword />,
  },
  {
    path: '404-not-found',
    element: <NotFound />,
  },
]

export default AuthRoutes
