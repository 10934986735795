import crypto, { Encoding } from 'crypto'

export class CryptoHelper {
  private static algorithm: string = 'aes-256-cbc'
  //Note must be 16 Byte initVectorString
  private static initVectorString: string = 'gY6gM3qO1xH0tK1w'
  //Note must be 32 Byte securitykeyString
  private static securityKeyString: string = 'iV9aJ0sF8rB3mS6tX7iX7vF4rP3dR6aK'

  static encryption(value: string, method: Encoding = 'hex'): string | null {
    //iv process
    let initVector: Uint8Array = this.convertToUint8Array(
      CryptoHelper.initVectorString
    )
    //security key process
    let securityKey: Uint8Array = this.convertToUint8Array(
      CryptoHelper.securityKeyString
    )
    // the cipher function
    const cipher = crypto.createCipheriv(
      this.algorithm,
      securityKey,
      initVector
    )

    // encrypt the message
    // input encoding
    // output encoding
    let encryptedData = cipher.update(value, 'utf8', method)
    encryptedData += cipher.final(method)

    return encryptedData
  }

  static decryption(value: string, method: Encoding = 'hex'): string | null {
    if (!value) {
      return null
    }
    //iv process
    let initVector: Uint8Array = this.convertToUint8Array(
      CryptoHelper.initVectorString
    )
    //security key process
    let securityKey: Uint8Array = this.convertToUint8Array(
      CryptoHelper.securityKeyString
    )
    // the decipher function
    const decipher = crypto.createDecipheriv(
      this.algorithm,
      securityKey,
      initVector
    )
    let decryptedData = decipher.update(value, method, 'utf8')
    decryptedData += decipher.final('utf8')

    return decryptedData
  }

  static convertToUint8Array(value: string): Uint8Array {
    var _Buf = new ArrayBuffer(value.length)
    let _Res: Uint8Array = new Uint8Array(_Buf)
    for (var i = 0, strLen = value.length; i < strLen; i++) {
      _Res[i] = value.charCodeAt(i)
    }
    return _Res
  }
}
